import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Student requests",
        subTitle: "Exceptions"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Requested exceptions",
        recordsCount: _ctx.applicationExceptions.count,
        subTitle: "Make a determination on application submitted with exceptions."
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            density: "compact",
            label: "Search name or application #",
            variant: "outlined",
            modelValue: _ctx.searchCriteria,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCriteria) = $event)),
            "data-cy": "searchExceptions",
            onKeyup: _withKeys(_ctx.searchExceptions, ["enter"]),
            "prepend-inner-icon": "mdi-magnify",
            "hide-details": "auto"
          }, null, 8, ["modelValue", "onKeyup"])
        ]),
        _: 1
      }, 8, ["recordsCount"]),
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.applicationExceptions.results?.length
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.applicationExceptions.results,
                lazy: true,
                class: "p-m-4",
                paginator: true,
                rows: _ctx.pageLimit,
                rowsPerPageOptions: _ctx.PAGINATION_LIST,
                totalRecords: _ctx.applicationExceptions.count,
                onPage: _ctx.pageEvent,
                onSort: _ctx.sortEvent
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "submittedDate",
                    sortable: true,
                    header: "Date submitted"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.submittedDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: "fullName",
                    header: "Name",
                    sortable: true
                  }),
                  _createVNode(_component_Column, {
                    field: "applicationNumber",
                    sortable: true,
                    header: "Application #"
                  }),
                  _createVNode(_component_Column, { header: "Action" }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: ($event: any) => (
                  _ctx.gotToAssessmentsSummary(
                    slotProps.data.applicationId,
                    slotProps.data.studentId,
                  )
                )
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("View")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords", "onPage", "onSort"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}