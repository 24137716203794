import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_status_chip_restriction = _resolveComponent("status-chip-restriction")!
  const _component_status_chip_bypass = _resolveComponent("status-chip-bypass")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_bypass_restriction_modal = _resolveComponent("bypass-restriction-modal")!
  const _component_remove_restriction_bypass_modal = _resolveComponent("remove-restriction-bypass-modal")!
  const _component_body_header_container = _resolveComponent("body-header-container")!

  return (_openBlock(), _createBlock(_component_body_header_container, { "enable-card-view": true }, {
    header: _withCtx(() => [
      _createVNode(_component_body_header, { title: "History of bypassed restrictions" }, {
        actions: _withCtx(() => [
          _createVNode(_component_check_permission_role, {
            role: _ctx.Role.AESTBypassStudentRestriction
          }, {
            default: _withCtx(({ notAllowed }) => [
              _createVNode(_component_v_btn, {
                class: "mx-2 float-right",
                color: "primary",
                disabled: notAllowed,
                onClick: _ctx.openBypassRestrictionModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Bypass a restriction ")
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["role"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_content_group, { class: "mt-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.bypassedRestrictions.bypasses.length,
            message: "No bypassed restrictions found."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_data_table, {
                headers: _ctx.ApplicationRestrictionManagementHeaders,
                items: _ctx.bypassedRestrictions.bypasses,
                "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                "items-per-page-options": _ctx.ITEMS_PER_PAGE
              }, {
                [`item.restrictionCategory`]: _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.restrictionCategory), 1)
                ]),
                [`item.restrictionCode`]: _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.restrictionCode), 1)
                ]),
                [`item.restrictionStatus`]: _withCtx(({ item }) => [
                  _createVNode(_component_status_chip_restriction, {
                    status: 
                item.isRestrictionActive
                  ? _ctx.RestrictionStatus.Active
                  : _ctx.RestrictionStatus.Resolved
              
                  }, null, 8, ["status"])
                ]),
                [`item.bypassStatus`]: _withCtx(({ item }) => [
                  _createVNode(_component_status_chip_bypass, {
                    "is-bypass-active": item.isBypassActive
                  }, null, 8, ["is-bypass-active"])
                ]),
                [`item.id`]: _withCtx(({ item }) => [
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    variant: "text",
                    class: "text-decoration-underline",
                    "prepend-icon": "fa:far fa-file-alt",
                    onClick: ($event: any) => (_ctx.openBypassViewDetailsModal(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" View Details")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                [`item.removeBypassRule`]: _withCtx(({ item }) => [
                  _createVNode(_component_check_permission_role, {
                    role: _ctx.Role.AESTBypassStudentRestriction
                  }, {
                    default: _withCtx(({ notAllowed }) => [
                      _createVNode(_component_v_btn, {
                        color: 
                    _ctx.getRemoveBypassColor(
                      item.isBypassActive,
                      item.isRestrictionActive,
                    )
                  ,
                        disabled: 
                    !item.isBypassActive ||
                    !item.isRestrictionActive ||
                    notAllowed
                  ,
                        onClick: ($event: any) => (_ctx.openRemoveBypassModal(item.id))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getRemoveBypassLabel(
                      item.isBypassActive && item.isRestrictionActive,
                    )), 1)
                        ]),
                        _: 2
                      }, 1032, ["color", "disabled", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["role"])
                ]),
                _: 2
              }, 1032, ["headers", "items", "items-per-page", "items-per-page-options"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      }),
      _createVNode(_component_bypass_restriction_modal, { ref: "bypassRestrictionModal" }, null, 512),
      _createVNode(_component_remove_restriction_bypass_modal, { ref: "removeBypassModal" }, null, 512)
    ]),
    _: 1
  }))
}