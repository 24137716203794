import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_status_chip_program = _resolveComponent("status-chip-program")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "All Programs",
            recordsCount: _ctx.institutionProgramsSummary.count
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        modelValue: _ctx.searchProgramName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchProgramName) = $event)),
                        label: "Search Program Name",
                        variant: "outlined",
                        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.goToSearch()), ["enter"])),
                        "prepend-inner-icon": "mdi-magnify",
                        "hide-details": "auto"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        density: "compact",
                        modelValue: _ctx.searchLocationName,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchLocationName) = $event)),
                        label: "Search Location Name",
                        variant: "outlined",
                        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.goToSearch()), ["enter"])),
                        "prepend-inner-icon": "mdi-magnify",
                        "hide-details": "auto"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        density: "compact",
                        modelValue: _ctx.searchProgramStatus,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchProgramStatus) = $event)),
                        label: "Search Program Status",
                        "hide-details": "auto",
                        items: _ctx.programStatusItems,
                        multiple: "",
                        variant: "outlined",
                        chips: ""
                      }, null, 8, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "1" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        class: "p-button-raised",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToSearch()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Search ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["recordsCount"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.loading && !_ctx.institutionProgramsSummary.count
              }, {
                default: _withCtx(() => [
                  (_ctx.institutionProgramsSummary?.count)
                    ? (_openBlock(), _createBlock(_component_v_data_table_server, {
                        key: 0,
                        headers: _ctx.ProgramHeaders,
                        items: _ctx.institutionProgramsSummary?.results,
                        "items-length": _ctx.institutionProgramsSummary?.count,
                        loading: _ctx.loading,
                        "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                        "items-per-page-options": _ctx.ITEMS_PER_PAGE,
                        "onUpdate:options": _ctx.pageSortEvent
                      }, {
                        [`item.submittedDate`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.submittedDateFormatted), 1)
                        ]),
                        [`item.programName`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.programName), 1)
                        ]),
                        [`item.locationName`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.locationName), 1)
                        ]),
                        [`item.totalOfferings`]: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.totalOfferings), 1)
                        ]),
                        [`item.programStatus`]: _withCtx(({ item }) => [
                          _createVNode(_component_status_chip_program, {
                            status: item.programStatus,
                            "is-active": item.isActive && !item.isExpired
                          }, null, 8, ["status", "is-active"])
                        ]),
                        [`item.action`]: _withCtx(({ item }) => [
                          _createVNode(_component_v_btn, {
                            variant: "outlined",
                            color: "primary",
                            onClick: ($event: any) => (_ctx.goToViewProgramDetail(item.programId, item.locationId))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("View")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["headers", "items", "items-length", "loading", "items-per-page", "items-per-page-options", "onUpdate:options"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}